import type { Config, Features, Test, Group, TestRes } from '@kiwicom/darwin';
import { mockRetrieve, mockFeatureRetrieve } from '@kiwicom/darwin/mock';
import { getTests, getFeatures } from '@kiwicom/darwin';
import { Cookie, save as saveCookie, remove as removeCookie } from '@kiwicom/cookies';
import { events, logServer } from '@kiwicom/account-tracking';
import { isObjectEmpty } from '@kiwicom/account-client-utils';
import refresh from '@kiwicom/nitro/lib/services/fetched/refresh';
import { format } from '@kiwicom/useragent';

const DARWIN_CONFIG = 'darwin/account.json';

async function fetchDarwinConfig(): Promise<Config> {
  try {
    const res = await refresh<Config>(DARWIN_CONFIG, {
      headers: {
        'User-Agent': format(
          'account-frontend',
          process.env.NEXT_PUBLIC_COMMIT_SHORT_SHA,
          process.env.APP_ENV,
        ),
      },
    });
    if (res) {
      return res;
    }
  } catch (err) {
    logServer(events.generalEvents.DARWIN_FETCH_FAILED, { error: err });
  }

  return {
    features: [],
    groups: [],
    winners: [],
  };
}

type Props = {
  readonly userAgent: string;
  readonly language: string;
  readonly visitorId: string;
  readonly searchParams: string;
  readonly country: string;
};
export type Darwin = {
  readonly tests: Test[] | null;
  readonly features: Features;
  readonly groups: Group[];
  readonly winners: Test[];
};

export default async function getDarwinConfig({
  userAgent,
  language,
  visitorId,
  searchParams,
  country,
}: Props): Promise<Darwin> {
  const config = await fetchDarwinConfig();

  const { groups, winners } = config;
  const mockedTests = mockRetrieve(searchParams);
  const { group, tests }: TestRes = getTests({
    config,
    userAgent,
    language,
    salt: visitorId,
  });

  if (group === null) {
    removeCookie(Cookie.DARWIN_SAVED_GROUPS);
  } else {
    saveCookie(Cookie.DARWIN_SAVED_GROUPS, group);
  }
  const allTests: Test[] = [...mockedTests, ...tests];
  const mockedFeatures = mockFeatureRetrieve(searchParams);

  const features: Features = isObjectEmpty(mockedFeatures)
    ? getFeatures({
        config,
        userAgent,
        language,
        country,
      })
    : mockedFeatures;

  return {
    tests: allTests,
    features,
    groups,
    winners,
  };
}
