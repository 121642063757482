import '../src/root.css';
import * as React from 'react';
import Script from 'next/script';
import type { AppContext } from 'next/app';
import App from 'next/app';
import type { NextPageContext } from 'next';
import Head from 'next/head';
import nextCookie from 'next-cookies';
import { useReportWebVitals } from 'next/web-vitals';
import { ThemeProvider } from 'styled-components';
import { OrbitProvider } from '@kiwicom/orbit-components';
import { StyledSystemTheme as styledSystemTheme, ToastRoot } from '@kiwicom/account-components';
import { AccountEnvironment, RelayEnvironmentProvider } from '@kiwicom/account-relay';
import { getBrandTheme } from '@kiwicom/nitro/lib/records/Theme';
import { useGetAccessToken } from '@kiwicom/nitro/lib/services/auth/context';
import type { UserLocation } from '@kiwicom/account-global-contexts';
import { RootProvider } from '@kiwicom/account-global-contexts';
import { captureException } from '@kiwicom/account-sentry';
import { isBrowser } from '@adeira/js';
import dynamic from 'next/dynamic';
import { getClientIp } from 'request-ip';
import { events } from '@kiwicom/account-tracking';
import { log, settings } from '@kiwicom/nitro/lib/services/log/logger';
import { DarwinProvider } from '@kiwicom/darwin';
import type { Darwin } from '@data-fetching/getDarwinConfig';
import getDarwinConfig from '@data-fetching/getDarwinConfig';
import fetchUserLocation from '@data-fetching/fetchUserLocation';
import fetchServerSideData from '@data-fetching/fetchServerSideData';
import type { NitroData } from '@data-fetching/loadNitroData';
import type { MessageFormatElement } from 'react-intl';
import { IntlProvider } from 'react-intl';
import { isWebview } from '@kiwicom/nitro/lib/services/platform/server';

import dateFnsLocaleMap from '../content/dateFnsLanguageMap';
import { GlobalLayoutContainer, GlobalStyle } from '../src/styles';
import NitroProvider from '../src/components/NitroProvider';
import { getFontFamily, normalizeQueryParam } from '../src/util';
import loadIntls from '../dataFetching/loadIntls';
import WebviewContext from '../services/WebviewContext';

const staticPath =
  process.env.STATIC_URL_PREFIX == null ? '' : `${process.env.STATIC_URL_PREFIX}/_next/static`;

const NitroBar = dynamic({
  loader: () => import('../src/components/NitroBar'),
});

const DebugModal = dynamic<{
  isDebugEnabled: boolean;
  gqlEndpoint: 'PRODUCTION' | 'SANDBOX';
  darwinConfig: Darwin;
}>({
  loader: () => import('@kiwicom/account-components').then((components) => components.DebugModal),
  ssr: false,
});

const CookiesPopupModal = dynamic({
  loader: () => import('../src/components/CookiesPopupModal'),
  ssr: false,
});

if (isBrowser() === true) {
  if (__DEV__) {
    Promise.all([import('react-dom'), import('@axe-core/react')])
      .then(async ([reactDom, reactAxe]) => {
        const axe = reactAxe.default;
        const reactDOM = reactDom.default;
        await axe(React, reactDOM, 1000);
      })
      .catch(() => {});
  }
}

// only log events if environment is production
// essentially, this will log events on preprod and prod
settings.enabled = process.env.APP_ENV === 'production';
type AnyObject = Readonly<Record<string, unknown>>;
type GQL_ENDPOINT = 'PRODUCTION' | 'SANDBOX';

type AccountPageContext = NextPageContext & {
  allCookies?: Record<string, string>;
};

type AppProps = Readonly<{
  pageProps: AnyObject;
  userLocation: UserLocation | null;
  nitroData: NitroData | null;
  gqlEndpoint: GQL_ENDPOINT;
  hasCookieConsent: boolean;
  currencyId: string;
  isDebugEnabled: boolean;
  isWebview: boolean;
  darwinConfig: Darwin;
  currentTranslationICU: { locale: string; messages: Record<string, MessageFormatElement[]> };
  err?: Error & { statusCode?: number };
}>;

const RelayEnvironment = ({ children, pageProps, language }) => {
  const getAccessToken = useGetAccessToken();
  const allCookies = pageProps.allCookies;

  // Create environment with the access token getter
  const environment = React.useMemo(() => {
    return AccountEnvironment.getEnvironment({
      token: '', // Initial token can be empty - it will be refreshed on first request
      ssrData: pageProps.ssrData,
      gcReleaseBufferSize: pageProps.gcReleaseBufferSize,
      language,
      cookies: allCookies,
      getAccessToken,
    });
  }, [pageProps.ssrData, pageProps.gcReleaseBufferSize, language, allCookies, getAccessToken]);

  return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
};

const getCookies = (ctx) => {
  const allCookies = nextCookie(ctx) ?? {};

  return {
    allCookies,
    gqlEndpoint: allCookies.gql_endpoint as GQL_ENDPOINT,
    enableMagicCookie: allCookies.enable_magic,
    currencyId: allCookies.preferred_currency,
    cookieConsent: allCookies.__kwc_agreed,
    ui: allCookies.ui,
    visitorId: allCookies.SKYPICKER_VISITOR_UNIQID,
  };
};

const getQueryParams = (query) => {
  return {
    language: query.language ?? 'en',
    enableMagicQuery: query.enable_magic,
    ui: query.ui,
  };
};

const getUrlSearchParams = ({ req }): string => {
  if (isBrowser() === true) {
    return window.location.search;
  }

  const reqUrl = new URL(`https://${req?.headers?.host}${req?.url}`);
  return reqUrl.search;
};

const AccountApp = ({
  Component,
  pageProps,
  err,
  nitroData,
  hasCookieConsent,
  isDebugEnabled,
  userLocation,
  gqlEndpoint,
  isWebview,
  darwinConfig,
  currentTranslationICU,
}) => {
  React.useEffect(() => {
    if (err != null) {
      captureException(err);
    }
  }, [err]);

  useReportWebVitals((metric) => {
    void log(events.webVitals.METRICS, {
      id: metric.id,
      name: metric.name,
      value: metric.value,
      startTime: metric.startTime,
    });
  });

  const { intlRaw, brand } = nitroData ?? { intlRaw: {}, brand: {} };
  const dateFnsCode = dateFnsLocaleMap[intlRaw.language.id];
  // This logic about the font family is already encapsulated in the getBrandTheme from Nitro in later
  // versions, but we need it here for now since we don't have the new version yet.
  const tokens = getBrandTheme(brand, intlRaw.language.direction === 'rtl');
  const fontFamily = getFontFamily({ language: intlRaw.language, brandDomain: brand.domain });
  const theme = {
    ...tokens,
    orbit: { ...tokens.orbit, fontFamily },
    ...styledSystemTheme(tokens),
  };

  const rootProviderData = {
    ssrData: {
      useLocalQueryRenderer: pageProps.useLocalQueryRenderer as boolean,
      fetchError: pageProps.fetchError === true,
    },
    geolocation: {
      userLocation,
    },
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <Script
        strategy="beforeInteractive"
        src={`${staticPath}/static/generated/${dateFnsCode}.js`}
      />
      <Script id="data-layer-script">dataLayer = [];</Script>
      <Script
        id="gtm-script"
        dangerouslySetInnerHTML={{
          // copied from GTM documentation
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KPB9P5');`,
        }}
      />
      <ThemeProvider theme={theme}>
        <OrbitProvider theme={theme} useId={React.useId}>
          <DarwinProvider
            features={darwinConfig.features}
            tests={darwinConfig.tests}
            winners={darwinConfig.winners}
          >
            <GlobalStyle
              backgroundColor={pageProps.backgroundColor as string}
              fontFamily={fontFamily}
            />
            <IntlProvider
              locale={intlRaw.language.id === 'us' ? 'en' : intlRaw.language.id}
              messages={currentTranslationICU.messages}
              defaultRichTextElements={{
                br: () => <br />,
                em: (text) => <em>{text}</em>,
                p: (text) => <p>{text}</p>,
                span: (text) => <span>{text}</span>,
                strong: (text) => <strong>{text}</strong>,
              }}
            >
              <NitroProvider intlRaw={intlRaw} brand={brand}>
                <RootProvider {...rootProviderData}>
                  <>
                    <NitroBar
                      nitroData={nitroData}
                      isWebview={isWebview}
                      useSimplifiedLoginFlow={pageProps.useSimplifiedLoginFlow as boolean}
                    />
                    <RelayEnvironment pageProps={pageProps} language={intlRaw.language.iso}>
                      <GlobalLayoutContainer isWebview={isWebview}>
                        <main>
                          <ToastRoot />
                          <WebviewContext.Provider value={isWebview}>
                            <Component {...pageProps} err={err} />
                          </WebviewContext.Provider>
                          <DebugModal
                            isDebugEnabled={isDebugEnabled}
                            gqlEndpoint={gqlEndpoint}
                            darwinConfig={darwinConfig}
                          />
                          {!hasCookieConsent && !isWebview && <CookiesPopupModal />}
                        </main>
                      </GlobalLayoutContainer>
                    </RelayEnvironment>
                  </>
                </RootProvider>
              </NitroProvider>
            </IntlProvider>
          </DarwinProvider>
        </OrbitProvider>
      </ThemeProvider>
    </>
  );
};

AccountApp.getInitialProps = async (props: AppContext): Promise<AppProps> => {
  const query = props.ctx.query ?? {};
  const { enableMagicQuery, language } = getQueryParams(query);
  const { allCookies, gqlEndpoint, enableMagicCookie, currencyId, cookieConsent, visitorId } =
    getCookies(props.ctx);
  const isDebugEnabled = enableMagicQuery === 'true' || enableMagicCookie != null;

  const isErrorPage = props.ctx.pathname === '/_error';
  let errorPageLocale = null;

  // this is an edge case because we have a custom 404 page where the language locale is not defined in the query params, we need to set it from somewhere else so that the 404 page gets translated correctly
  if (isErrorPage) {
    errorPageLocale = props.ctx.asPath.split(/\//)[1];
  }

  const hasCookieConsent = cookieConsent === 'true';

  const clientIp = props.ctx.req != null ? getClientIp(props.ctx.req) : '';

  const [userLocation] = await Promise.all([fetchUserLocation(clientIp)]);
  let { pageProps } = await App.getInitialProps({
    ...props,
    ctx: { ...props.ctx, allCookies, clientIp } as AccountPageContext,
  });
  let ssrData = null;
  let allTranslationsICU = null;
  let currentTranslationICU = null;
  let nitroData = null;

  if (isBrowser() === true) {
    const nextDataScript = document.querySelector('#__NEXT_DATA__');
    const nextData = JSON.parse(nextDataScript?.textContent ?? '{}');
    nitroData = nextData?.props?.nitroData;
    currentTranslationICU = nextData?.props?.currentTranslationICU;
    pageProps = { ...pageProps };
  } else {
    const ssr = await fetchServerSideData(props.ctx, {
      query: pageProps?.query,
      variables: pageProps?.variables ?? {},
      isSecuredPage: pageProps.isSecuredPage,
    });
    allTranslationsICU = await loadIntls();
    nitroData = ssr.nitroData ?? null;
    ssrData = ssr.ssrData ?? null;
  }

  const darwinConfig = await getDarwinConfig({
    userAgent: normalizeQueryParam(props.ctx.req?.headers['User-Agent'] ?? ''),
    language: nitroData?.intlRaw?.language?.phraseApp ?? '',
    // do not set "" as default value, it will cause Error: `[@kiwicom/darwin] Too many tests (${saltTests.length}) for the salt length.`
    visitorId: visitorId ? visitorId : 'visitorId-fallback-string-due-to-missing-visitorId',
    searchParams: getUrlSearchParams({ req: props.ctx.req }),
    // KW-Country is set by CF workers
    country: normalizeQueryParam(props.ctx.req?.headers['kw-country'] ?? ''),
  });

  const wasTranslationLoadedInBrowser = currentTranslationICU != null;
  const serverLoadedTranslation =
    allTranslationsICU && allTranslationsICU[(isErrorPage ? errorPageLocale : language) ?? 'en'];

  return {
    pageProps: {
      ...pageProps,
      ...ssrData,
    },
    nitroData,
    currentTranslationICU: wasTranslationLoadedInBrowser
      ? currentTranslationICU
      : serverLoadedTranslation,
    isDebugEnabled,
    currencyId,
    hasCookieConsent,
    gqlEndpoint,
    userLocation,
    isWebview: isWebview({ req: props.ctx.req }),
    darwinConfig,
  };
};

export default AccountApp;
