import { isBrowser } from '@adeira/js';

const getLocaleFromPath = (): string => {
  if (isBrowser()) {
    const pathSegments = window.location.pathname.split('/');
    // The locale is the first segment after the leading slash in our [language] route structure
    if (pathSegments.length > 1 && pathSegments[1]) {
      return pathSegments[1];
    }
  }
  return '';
};

export default getLocaleFromPath;
