import type { Variables, GraphQLResponse } from 'relay-runtime';
import fetch from '@adeira/fetch';
import { isBrowser, invariant } from '@adeira/js';
import { captureMessage } from '@kiwicom/account-sentry';
import jsCookies from 'js-cookie';
import ACCOUNT_CONSTS from '@kiwicom/account-consts';
import { format } from '@kiwicom/useragent';
import { getPlatform } from '@kiwicom/nitro/lib/services/platform/client';
import { load as getSession } from '@kiwicom/nitro/lib/services/session/session';
import * as sessionConsts from '@kiwicom/nitro/lib/consts/session';
import { isWebview } from '@kiwicom/nitro/lib/services/platform/client';
import { getLocaleFromPath } from '@kiwicom/account-client-utils';

invariant(
  process.env.PLEXUS_URL != null,
  'You are missing PLEXUS_URL in your environment, and you need to set it',
);
invariant(
  process.env.PLEXUS_PRODUCTION_URL != null,
  'You are missing PLEXUS_PRODUCTION_URL in your environment, and you need to set it',
);
invariant(
  process.env.PLEXUS_SANDBOX_URL != null,
  'You are missing PLEXUS_SANDBOX_URL in your environment, and you need to set it',
);
export const endpoints = {
  PRODUCTION: process.env.PLEXUS_PRODUCTION_URL,
  SANDBOX: process.env.PLEXUS_SANDBOX_URL,
  CYPRESS: process.env.CYPRESS_ENDPOINT_URL ?? ('http://localhost:4000' as string),
};
type Request =
  | {
      readonly text: string;
    }
  | {
      readonly id: string;
    };
type ServerCookies = Readonly<Record<string, string>>;
type Uploadables = Readonly<Record<string, File | Blob>>;
type FetchOptions = {
  readonly token: string;
  readonly language: string;
  readonly cookies?: ServerCookies;
  readonly getAccessToken?: () => Promise<string>;
};

const getCookie = (name: string, cookies: ServerCookies | null | undefined) => {
  if (isBrowser() === true) {
    return jsCookies.get(name);
  }

  if (cookies == null) {
    return null;
  }

  return cookies[name];
};

type RequestBody = {
  queryId?: string;
  query?: string;
  readonly variables: Variables;
};

function getBodyContent(request, variables): RequestBody {
  const body: {
    queryId?: string;
    query?: string;
    variables: Variables;
  } = {
    variables,
  };

  if (request.id != null) {
    body.queryId = request.id;
  } else if (request.text != null) {
    body.query = request.text;
  }

  return body;
}

function getRequestBodyWithoutUplodables(request, variables): string {
  return JSON.stringify(getBodyContent(request, variables));
}

function getRequestBodyWithUploadables(request, variables, uploadables): FormData {
  const formData = new FormData();
  const body = getBodyContent(request, variables);
  const operations: RequestBody = {
    variables: body.variables,
  };

  if (body.queryId != null) {
    operations.queryId = body.queryId;
  } else if (body.query != null) {
    operations.query = body.query;
  }

  formData.append('operations', JSON.stringify(operations));
  const fileMap = {};
  let index = 0;

  for (const key of Object.keys(uploadables)) {
    fileMap[index] = [`variables.${key}`];
  }

  // Node-js graphql-upload needs map to be set before the files.
  // Setting map after will cause 400 on the mock server 🙈
  formData.append('map', JSON.stringify(fileMap));

  for (const value of Object.values(uploadables)) {
    const file: File = value as File;
    formData.append(`${index++}`, file, file.name);
  }

  return formData;
}

function getRequestBody(
  request: Request,
  variables: Variables,
  uploadables: Uploadables | null | undefined,
): string | FormData {
  if (uploadables) {
    return getRequestBodyWithUploadables(request, variables, uploadables);
  }

  return getRequestBodyWithoutUplodables(request, variables);
}

const getHeaders = ({ cypressUser, uploadables, language, token, cookies }) => {
  const platform = getPlatform();

  const headers: {
    'X-Client': string;
    'Accept-Language': string;
    Authorization?: string;
    'Content-Type'?: string;
    'x-cypress-user'?: string;
    'User-Agent'?: string;
    'X-Analytics-Is-Webview'?: string;
    'X-Analytics-Currency'?: string;
    'X-Analytics-Language'?: string;
    'X-Analytics-Locale'?: string;
    'X-Analytics-Market'?: string;
    'X-Analytics-Visitor-Session-Id'?: string;
    'X-Analytics-Session-Id'?: string;
    'X-WHOIAM'?: string;
    'X-Analytics-Referer'?: string;
    'X-Analytics-Url'?: string;
  } = {
    'X-Client': `Account-FE ${platform}`,
    'Accept-Language': language,
    'X-Analytics-Is-Webview': String(isWebview()),
    'X-Analytics-Currency': getCookie('currency', cookies) ?? '',
    'X-Analytics-Language': getCookie('kw-language', cookies) ?? '',
    'X-Analytics-Locale': getLocaleFromPath() ?? '',
    'X-Analytics-Market': getCookie('kw-market', cookies) ?? '',
    'X-Analytics-Visitor-Session-Id': getCookie('kw-session-id', cookies) ?? '',
    'X-Analytics-Session-Id': getSession(sessionConsts.SESSION_ID) ?? '',
    'X-WHOIAM': getCookie('user_id', cookies) ?? '',
    'X-Analytics-Referer': typeof document !== 'undefined' ? document?.referrer || '' : '',
    'X-Analytics-Url': typeof window !== 'undefined' ? window?.location?.href || '' : '',
  };

  const { X_CYPRESS_USER } = ACCOUNT_CONSTS.COOKIES;

  if (uploadables == null) {
    // Note that we only have to set content type when we are not sending uploadables.
    // Relay takes care of correct content-type with uploadables
    headers['Content-Type'] = 'application/json';
  }

  if (cypressUser) {
    headers[X_CYPRESS_USER] = cypressUser;
  }

  if (token != null && token !== '') {
    headers.Authorization = `Bearer ${token}`;
  }

  if (!isBrowser()) {
    headers['User-Agent'] = format(
      'account-frontend',
      process.env.NEXT_PUBLIC_COMMIT_SHORT_SHA,
      process.env.APP_ENV,
    );
  }

  return headers;
};
export default function fetchFn({ token, language, cookies, getAccessToken }: FetchOptions) {
  const fetchWithFreshToken = async (
    request: Request,
    variables: Variables,
    uploadables?: Uploadables | null | undefined,
  ): Promise<GraphQLResponse> => {
    const { X_CYPRESS_USER, GQL_ENDPOINT, USE_CYPRESS_MOCK } = ACCOUNT_CONSTS.COOKIES;

    const cypressUser = getCookie(X_CYPRESS_USER, cookies) ?? '';
    const useCypressMock = getCookie(USE_CYPRESS_MOCK, cookies);
    const gqlEndpoint = getCookie(GQL_ENDPOINT, cookies);

    const getUrl = () => {
      if (useCypressMock != null) {
        return endpoints.CYPRESS;
      }

      // this is used for setting the endpoint manually in debug modal
      if (gqlEndpoint != null && (gqlEndpoint === 'PRODUCTION' || gqlEndpoint === 'SANDBOX')) {
        return endpoints[gqlEndpoint];
      }

      return process.env.PLEXUS_URL;
    };

    // Get a fresh token if possible
    let currentToken = token;
    if (isBrowser() && getAccessToken) {
      try {
        currentToken = await getAccessToken();
      } catch (error) {
        console.error('Failed to get fresh access token:', error);
      }
    }

    const rawResponse = await fetch(getUrl(), {
      method: 'POST',
      headers: getHeaders({
        cypressUser,
        uploadables,
        language,
        cookies,
        token: currentToken,
      }),
      body: getRequestBody(request, variables, uploadables),
      retryDelays: isBrowser() ? [1000, 3000] : [],
      // Keeping the defaults in browser, no retries on server
      fetchTimeout: isBrowser() ? 15000 : 7500, // Keeping default in browser, timeout after 7.5 seconds on server
    });

    const response = await rawResponse.json();

    if (response.errors) {
      response.errors.forEach((error) => {
        console.warn(error);

        if (error.message.toLowerCase() !== 'unauthorized') {
          // Being Unauthorized is not really an error
          captureMessage(`Relay fetch error: ${error.message}`, error);
        }
      });
    }

    return response;
  };

  return fetchWithFreshToken;
}
