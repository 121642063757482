import type { Environment } from 'react-relay';
import 'react-relay';
import { isBrowser } from '@adeira/js';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';

import createEnvironment from './createEnvironment/createEnvironment';
import createFetchFunction from './createFetchFunction';

type EnvironmentArguments = {
  readonly token: string;
  readonly ssrData?: RecordMap | null | undefined;
  readonly gcReleaseBufferSize?: number | null | undefined;
  readonly language: string;
  readonly cookies?: Readonly<Record<string, string>>;
  readonly getAccessToken?: () => Promise<string>;
};

class AccountEnvironment {
  #environment: Environment | null;
  #token: string | null;
  #getAccessToken: (() => Promise<string>) | null;

  constructor() {
    this.#environment = null;
    this.#token = null;
    this.#getAccessToken = null;
  }

  getEnvironment(args: EnvironmentArguments): Environment {
    const { token } = args;

    // Always re create the environment on server
    if (this.#token === token && this.#environment !== null && isBrowser() === true) {
      return this.#environment;
    }

    return this.createEnvironment(args);
  }

  createEnvironment({
    token,
    ssrData,
    gcReleaseBufferSize = 50,
    language,
    cookies,
    getAccessToken,
  }: EnvironmentArguments): Environment {
    this.#token = token;

    // Store the access token getter if provided
    if (getAccessToken) {
      this.#getAccessToken = getAccessToken;
    }

    const fetchFn = createFetchFunction({
      token,
      language,
      cookies,
      getAccessToken: this.#getAccessToken,
    });

    this.#environment = createEnvironment({
      fetchFn,
      records: ssrData,
      // This will tell relay how many request to store before garbage collecting. Default is 0
      gcReleaseBufferSize: isBrowser() === true ? gcReleaseBufferSize : 0, // No caching on the server
    });
    return this.#environment;
  }
}

const environment: AccountEnvironment = new AccountEnvironment();
export default environment;
