import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const oAuthRedirect: EventList = {
  OAUTH_INVALID_PARAMETERS: {
    ...commonFields,
    category: 'oAuth redirect',
    action: 'Invalid or empty oAuth parameters',
  },
  OAUTH_INVALID_PROVIDER: {
    ...commonFields,
    category: 'oAuth redirect',
    action: 'Invalid or empty oAuth provider',
  },
  OAUTH_INVALID_REDIRECT_PATH: {
    ...commonFields,
    category: 'oAuth redirect',
    action: 'Invalid or empty redirect path',
  },
  OAUTH_USER_FETCH_ERROR: {
    ...commonFields,
    category: 'oAuth redirect',
    action: 'Failure to fetch user information',
  },
  OAUTH_LOGIN_ERROR: {
    ...commonFields,
    category: 'oAuth redirect',
    action: 'oAuth login error',
  },
  OAUTH_SUCCESSFULL_LOGIN: {
    ...commonFields,
    category: 'oAuth redirect',
    action: 'Successfull oAuth login',
  },
};
